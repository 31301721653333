// import React, { useState, useEffect } from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   useLocation,
// } from "react-router-dom";
// import { AnimatePresence, motion } from "framer-motion";
// import Header from "./Header";
// import Footer from "./Footer";
// import Categorie from "./Categorie";
// import CautariCategorie from "./cautariCategorie";
// import PromotedSlider from "./PromotedSlider";
// import NotFound from "./NotFound";
// import PrivacyScreen from "./PrivacyScreensComponent/PrivacyScreen";
// import isMobileDevice from "./utils/deviceDetection.js";
// import DownloadApp from "./utils/downloadApp.js";
// import AuthWrapper from "./AuthenticateComponents/authenticate.js";
// import Orders from "./orders.js";
// import CardDetail from "./CardDetail.js";
// import ScrollToTop from "./utils/ScrollToTop.js";
// import RegisterProvider from "./AuthenticateComponents/registerProvider.js";
// import ProviderDashboard from "./ProviderDashboard/providerDashboard.js";
// import ChatMessages from "./chatMessages.js";

// const pageVariants = {
//   initial: { opacity: 0, x: "-100vw" },
//   in: { opacity: 1, x: 0 },
//   out: { opacity: 0, x: "100vw" },
// };

// const pageTransition = { type: "tween", ease: "anticipate", duration: 0.5 };

// function AnimatedRoutes() {
//   const [showPrivacyScreen, setShowPrivacyScreen] = useState(false);
//   const [previousPath, setPreviousPath] = useState("");
//   const location = useLocation();

//   useEffect(() => {
//     if (location.pathname === "/404") {
//       setShowPrivacyScreen(true);
//     } else {
//       setTimeout(() => {
//         setShowPrivacyScreen(false);
//       }, 800);
//     }
//   }, [location]);

//   useEffect(() => {
//     setPreviousPath(location.pathname);
//   }, [location.pathname]);

//   const shouldAnimate = previousPath === "/404" && location.pathname === "/";

//   const isLoggedIn = Boolean(localStorage.getItem("api_key"));

//   return (
//     <>
//       <PrivacyScreen isVisible={showPrivacyScreen} />
//       <ScrollToTop />
//       <AnimatePresence mode="wait">
//         <Routes location={location} key={location.pathname}>
//           <Route
//             path="/"
//             element={shouldAnimate ? <AnimatedHome /> : <HomePage />}
//           />
//           <Route path="/cautari-categorie" element={<CautariCategorie />} />
//           <Route
//             path="/404"
//             element={
//               <NotFound
//                 onStartPrivacyScreen={() => setShowPrivacyScreen(true)}
//               />
//             }
//           />
//           <Route
//             path="*"
//             element={
//               <NotFound
//                 onStartPrivacyScreen={() => setShowPrivacyScreen(true)}
//               />
//             }
//           />
//           <Route path="/authenticate" element={<AuthWrapper />} />
//           <Route
//             path="/orders"
//             element={
//               isLoggedIn ? (
//                 <Orders />
//               ) : (
//                 <NotFound
//                   onStartPrivacyScreen={() => setShowPrivacyScreen(true)}
//                 />
//               )
//             }
//           />
//           <Route path="/card/:id" element={<CardDetail />} />
//           <Route path="/provider" element={<RegisterProvider />} />
//           <Route path="/dashboard" element={<ProviderDashboard />} />
//           <Route path="/chat" element={<ChatMessages />} />
//         </Routes>
//       </AnimatePresence>
//     </>
//   );
// }

// function AnimatedHome() {
//   return (
//     <motion.div
//       initial="initial"
//       animate="in"
//       exit="out"
//       variants={pageVariants}
//       transition={pageTransition}
//     >
//       <HomePage />
//     </motion.div>
//   );
// }

// function HomePage() {
//   return (
//     <>
//       <div className="text-3xl text-white ml-10 mb-10 pt-5">Categorii</div>
//       <Categorie />
//       <div className="text-3xl text-white mt-10 ml-10 mb-5">
//         Incearca-i pe cei mai buni
//       </div>
//       <PromotedSlider />
//     </>
//   );
// }

// function App() {
//   const location = useLocation();
//   const shouldHideHeader =
//     location.pathname === "/download-app" ||
//     location.pathname === "/authenticate" ||
//     location.pathname === "/404" ||
//     location.pathname === "/provider" ||
//     location.pathname === "/dashboard";

//   return (
//     <div>
//       {!shouldHideHeader && <Header />}
//       <AnimatedRoutes />
//       <Footer />
//     </div>
//   );
// }

// export default function AppWrapper() {
//   const [showNotification, setShowNotification] = useState(false);

//   useEffect(() => {
//     if (isMobileDevice()) {
//       setShowNotification(true);
//     }
//   }, []);

//   return (
//     <Router>
//       {showNotification && (
//         <DownloadApp setShowNotification={setShowNotification} />
//       )}

//       <App />
//     </Router>
//   );
//}
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Wip from "./wip"; // Ensure that the Wip component is correctly imported

export default function App() {
  return (
    <Router>
      <Routes>
        {/* Define your main route */}
        <Route path="/" element={<Wip />} />

        {/* Catch-all route that redirects any undefined paths to the home page */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}
